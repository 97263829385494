import Locale from "../../modules/common/constants/Locale";

/**
 * Params represent dynamic values passed as route params
 * Note: prefix with "param" to prevent collisions with Path
 */
export enum Params {
  lang = "param_lang",
  slug_articleId = "param_slug_articleId",
  slug_vehicleId = "param_slug_vehicleId",
  slug_dealerId = "param_slug_dealerId",
  slug_brand = "param_slug_brand",
  slug_adId = "param_slug_adId",
  slug_model = "param_slug_model",
  slug_promotionId = "param_slug_promotionId",
  pageId = "param_pageId",
  step = "param_step",
}

export const FALLBACK: { [key in Params]: string } = {
  [Params.lang]: Locale.German,
  [Params.slug_articleId]: "MISSING_SLUG_ARTICLE_ID",
  [Params.slug_vehicleId]: "MISSING_SLUG_VEHICLE_ID",
  [Params.slug_dealerId]: "MISSING_SLUG_DEALER_ID",
  [Params.slug_adId]: "MISSING_SLUG_AD_ID",
  [Params.slug_brand]: "MISSING_SLUG_BRAND_ID",
  [Params.slug_model]: "MISSING_SLUG_MODEL_ID",
  [Params.slug_promotionId]: "MISSING_SLUG_PROMOTION_ID",
  [Params.pageId]: "MISSING_PAGE_ID",
  [Params.step]: "MISSING_STEP",
};

// Key for retrieving the wildcard from useParams
export const PARAM_WILDCARD = "*";
